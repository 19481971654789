@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');

.logoAreaContainer {
    color: var(--bs-white);
}

.logoAreaContainer > div { /* Targets flexbox container */
    height: auto;
}

.brandingContainer {
    cursor: pointer;
}

.branding {
    font-family: 'Bakbak One' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 2.75rem;
    line-height: 2.75rem !important;
}

.lang {
    cursor: pointer;
    transition: 0.5s ease all;
}

.lang:hover {
    color: #3366FF;
}

.contactIcon {
    width: 2.5rem;
    height: 2.5rem;
    fill: var(--bs-white);
    margin-right: 0.75rem;
    cursor: pointer;
    transition: 0.5s ease all;
}

.contactIcon:hover {
    fill: #3366FF;
}
