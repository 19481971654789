/* Input range - chrome and safari */
input[type="range"] {
    --range-progress: 0%;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    background: var(--bs-gray-dark);
    width: 60%;
    height: 0.75rem;
    border-radius: 0.2rem;
    cursor: pointer;
}
  
/* Input range - firefox */
input[type="range"]::-moz-range-track {
  position: relative;
  background: var(--bs-gray-dark);
  width: 60%;
  height: 0.75rem;
  border-radius: 0.2rem;
  cursor: pointer;
}

/* played progress length - Chrome & safari*/
/* input[type="range"]::before {
  content: "";
  height: 0.75rem;
  background: #3366FF;
  width: var(--range-progress);
  border-bottom-left-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  position: absolute;
  top: 0;
  left: 0;
} */

/* played progress length - firefox */
/* input[type="range"]::-moz-range-progress {
  background: #3366FF;
  border-bottom-left-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  height: 0.75rem;
} */

/* slider thumb - chrome and safari */
input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.2rem;
  border: none;
  background-color: var(--bs-white);
  cursor: pointer;
  position: relative;
}

/* dragging thumb - chrome and safari */
input[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

/* slider thumb - firefox */
input[type="range"]::-moz-range-thumb {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  background: var(--bs-white);
  cursor: pointer;
  border: transparent;
  position: relative;
}

/* dragging thumb - firefox */
input[type="range"]:active::-moz-range-thumb {
  transform: scale(1.2);
}

.time {
  color: var(--bs-gray-200);
}

.current {
  color: var(--bs-white);
}