html {
  font-size: 16px;
  min-height: 100vh;
}

body {
  position: relative;
  margin: 0;
  min-height: inherit;
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

#root {
  min-height: inherit;
  opacity: 0;
}

/* Bootstrap overrides */
.modal-content {
  border-radius: 0;
}