.teaserAreaContainer {
    color: var(--bs-white);
}

.teaserAreaContainer > div { /* Targets flexbox container */
    height: auto;
}

.teaserHeader span {
    display: inline-block;
    color: var(--bs-gray-300);
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
}

.platformIcon {
    width: 2.5rem;
    height: 2.5rem;
    fill: var(--bs-white);
    margin-right: 0.75rem;
    cursor: pointer;
    transition: 0.5s ease all;
}

.platformIcon.end {
    margin-right: 0;
}

.platformIcon:hover {
    fill: #3366FF;
}
