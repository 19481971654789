@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');

.img {
  max-width: 65%;
}

.title {
  font-weight: 100;
  letter-spacing: 0.1rem;
  color: var(--bs-gray-200);
}

@media screen and (max-width: 767.98px) {
  .img {
    max-width: 80%;
  }
}